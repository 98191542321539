<template>
    <div class="container-fluid">
        <div class="card p-1 mb-2">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="watchFilters">
                        <b-icon-arrow-repeat scale="1.2"/>
                    </b-button>
                    <b-button v-b-toggle.filters variant="light">Фильтры</b-button>
                    <small v-if="filtersExists">
                        <b-link @click.prevent="reset">(Сбросить)</b-link>
                    </small>
                </b-col>
            </b-row>
            <b-collapse id="filters" visible>
                <div class="card-body">
                    <b-form-group description="Номенклатура">
                        <nomenclature-input v-model="filters.nomenclature"/>
                    </b-form-group>
                    <div class="row">
                        <div class="col-md-3">
                            <b-form-group description="Цех">
                                <storage-picker v-model="filters.storage" nullable/>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group description="Тип акта">
                                <act-type-picker v-model="filters.actType"/>
                            </b-form-group>
                        </div>
                        <div class="col-md-3">
                            <b-form-group>
                                <b-checkbox v-model="filters.incoming">Поступления</b-checkbox>
                                <b-checkbox v-model="filters.outgoing">Списания</b-checkbox>
                            </b-form-group>
                        </div>
                    </div>
                </div>
            </b-collapse>
        </div>
        <div class="table-responsive">
            <table class="table table-hover">
                <thead class="thead-dark">
                <tr>
                    <th>№</th>
                    <th>Номенклатура</th>
                    <th>Партия</th>
                    <th class="text-right">До</th>
                    <th class="text-right">Кол-во</th>
                    <th class="text-right">После</th>
                    <th>Акт</th>
                    <th>Цех</th>
                    <th>Дата</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in items">
                    <td>{{item.id}}</td>
                    <td>{{item.nomenclature.name}}</td>
                    <td>{{item.nomenclatureLot ? item.nomenclatureLot.name : '---'}}</td>
                    <td class="text-right"><b>{{parseFloat(item.count_before).toFixed(3)}}</b></td>
                    <td class="text-right"><b>{{parseFloat(item.count).toFixed(3)}}</b></td>
                    <td class="text-right"><b>{{parseFloat(item.count_after).toFixed(3)}}</b></td>
                    <td>
                        <router-link
                            v-if="getLink(item) !== null"
                            target="_blank"
                            :to="getLink(item)">[{{item.act_id}}] {{item.actType.name}}</router-link>
                        <span v-else>[{{item.act_id}}] {{item.actType.name}}</span>
                    </td>
                    <td>{{item.storage.name}}</td>
                    <td>{{moment(item.date).format('DD.MM.YYYY HH:mm:ss')}}</td>
                </tr>
                </tbody>
            </table>
        </div>


        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="applyFilters"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </div>
</template>

<script>
    import NomenclatureInput from "@components/_common/NomenclatureInput";
    import NomenclatureLotPick from "@components/_common/NomenclatureLotPick";
    import StoragePicker from "@components/_common/StoragePicker";
    import ActTypePicker from "@components/_common/ActTypePicker";
    import lsfMixin from "@utils/localStorageFilterMixin";
    import {STORAGE_MOVEMENT_ENDPOINT} from '@utils/endpoints';
    import moment from 'moment';

    const FILTER_PATTERN = {
        nomenclature: null,
        storage: null,
        actType: null,
        incoming: null,
        outgoing: null,
        page: null,
    };

    export default {
        name: "MovementHistory",
        mixins: [lsfMixin],
        components: {
            NomenclatureInput,
            NomenclatureLotPick,
            StoragePicker,
            ActTypePicker
        },
        data() {
            return {
                items: [],
                filters: {
                    nomenclature: null,
                    storage: null,
                    actType: null,
                    incoming: true,
                    outgoing: true,
                },
                meta: {
                    total: null,
                    current_page: null,
                    last_page: null,
                }
            };
        },
        watch: {
            filters: {
                deep: true,
                handler (value) {
                    this.watchFilters(value);
                },
            },
        },
        computed: {
            params() {
                let params = {
                    page: this.meta.current_page,
                    sort: 'date',
                    sortDirection: 'desc',
                    with: [
                        'nomenclature',
                        'nomenclatureLot',
                        'storage',
                        'actType'
                    ]
                };
                if (!!this.filters.nomenclature) {
                    params['filters[nomenclatureId]'] = this.filters.nomenclature.id;
                }

                if (!!this.filters.storage) {
                    params['filters[storageIds][]'] = this.filters.storage.id;
                }

                if (!!this.filters.actType) {
                    params['filters[actTypeId]'] = this.filters.actType.id;
                }

                if (!(!!this.filters.incoming)) {
                    // Если отключены поступления, не отображаем ихи
                    params['filters[incoming]'] = 0;
                }

                if (!(!!this.filters.outgoing)) {
                    // Если отключены списания, не отображаем ихи
                    params['filters[outgoing]'] = 0;
                }

                params['filters[withTrashed]'] = 1;

                return params;
            },
        },
        methods: {
            moment,
            applyFilters() {
                this.$http.get(STORAGE_MOVEMENT_ENDPOINT, {
                    params: this.params
                }).then(response => {
                    this.items = response.data.data.data;
                    this.meta.current_page = response.data.data.current_page;
                    this.meta.total_rows = response.data.data.total;
                    this.meta.per_page = response.data.data.per_page;
                })
            },
            getLink(item) {
                let name = null;
                switch (item.actType.id) {
                    case 1:
                        name = 'AdmissionActEdit';
                        break;
                    case 3:
                        name = 'MaterialTransferActUpdate';
                        break;
                    case 4:
                        name = 'ProductionActUpdate';
                        break;
                    case 5:
                        name = 'ShiftClosingWizard';
                        break;
                    case 6:
                        name = 'OrderToContractorActForm';
                        break;
                    case 7:
                        name = 'QualityControlActUpdate';
                        break;
                    case 8:
                        name = "InventoryActUpdate";
                        break;
                }

                if (name !== null) {
                    return {
                        name: name,
                        params: {
                            id: item.act_id
                        }
                    };
                }

                return null;
            },
            reset() {
                this.filters.nomenclature = null;
                this.filters.storage = null;
                this.filters.actType = null;
                this.meta.current_page = 1;
            },
        },
        created() {
            this.initFilters(this, FILTER_PATTERN);
        }
    }
</script>
