<template>
    <select v-model="internalValue" class="form-control" @change="onChange" :disabled="disabled">
        <option :value="null" :disabled="!nullable">Выберите тип акта</option>
        <option v-for="option in options" :value="option.id">
            {{ option.name }}
        </option>
    </select>
</template>

<script>
    import {ACT_TYPE_ENDPOINT} from "@utils/endpoints";
    export default {
        props: {
            value: {},
            disabled: {
                type: Boolean,
                default: false
            },
            nullable: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            options() {
                return this.types;
            },
        },
        watch: {
            value() {
                this.internalValue = this.value ? this.value.id : null
            },
        },
        data() {
            return {
                internalValue: this.value ? this.value.id : null,
                types: [],
            }
        },
        methods: {
            onChange() {
                this.$emit('input', this.options.find(o => o.id === this.internalValue))
            },
            getParams() {
                let params = {
                    limit: 999,
                    filters: {}
                };

                return params;
            },
            loadTypes() {
                this.$http.get(ACT_TYPE_ENDPOINT, {
                    params: this.getParams()
                }).then( response => {
                    this.types = response.data.data.data;
                }).catch(res => this.$root.responseError(res, 'Ошибка загрузки типов актов'));
            },
        },
        mounted() {
            this.loadTypes();
        }
    }
</script>
